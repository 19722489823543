import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleService } from '../core/services/user-role.service';

@Component({
  selector: 'app-no-access-modal',
  templateUrl: './no-access-modal.component.html',
  styleUrls: ['./no-access-modal.component.scss']
})
export class NoAccessModalComponent implements OnInit {

  constructor(private router:Router,private userRoleService:UserRoleService) { }

  ngOnInit(): void {
  }

  onLogout() {
    // Clear session data and navigate to login
    sessionStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['signin']).then(() => {
      console.log('reload called')
    setTimeout(() => {
      document.location.reload();
    },1000);
    });
    this.userRoleService.clear();
  }

}
