export const SidebarMenuItem:any = [
  {
    'title': 'Dashboard', show: false, icon: "fas fa-tachometer-alt", 'defaultUrl': '/teamium/dashboard/agenda',  'defaultSubmenuUrl': '/teamium/dashboard/agenda','defalutSubmenuOpen': 'true', 'children':[

      { title: 'Agenda', show: false, link: '/teamium/dashboard/agenda' },
      { title: 'Scheduler',show: false, link: '/teamium/dashboard/global-kit' },
      { title: 'Roster', show: false, link: '/teamium/dashboard/collaborator-roster' },
      { title: 'Leave', show: false, link: '/teamium/dashboard/collaborator-leave' },
      { title: 'Project', show: false, link: '/teamium/dashboard/project' },
      { title: 'Tasks', show: false, link: '/teamium/dashboard/process' },
      { title: 'Incident', show: false, link: '/teamium/dashboard/incident' },
      { title: 'Work Order', show: false, link: '/teamium/dashboard/work-order' },
      { title: 'Transmission', show: false, link: '/teamium/dashboard/transmission-order' },
      { title: 'Rundown', show: false, link: '/teamium/dashboard/project-rundown' },
      { title: 'Editorial Rundown', show: false, link: '/teamium/dashboard/editorial-rundown' },
      { title: 'Financial Rundown', show: false, link: '/teamium/dashboard/financial-rundown' },
      { title: 'KPI', show: false, link: '/teamium/dashboard/kpi' },
      { title: 'Project phase', show: false, link: '/teamium/dashboard/warehouse' },
      { title: 'Check In', show: false, link: '/teamium/dashboard/check-in-page' },
      { title: 'Vendor', show: false, link: '/teamium/dashboard/vendor-bidding' },
    ]
  },
  {
    'title': 'Shows', show: false, icon: "fas fa-align-left", 'defaultUrl': '/teamium/show/show-list', 'defaultSubmenuUrl': '/teamium/show/show-list', 
    'children': [
      // { 'title': 'Estimate', show: false,'link': '/teamium/show/show-estimate', 'pathVariable': 1, 'pathAttributes': ['showId'],'defaultSubmenuUrl': '/teamium/show-budgeting/call-sheet', 'subPathAttributes': ['showId'] },
      // { 'title': 'Budget', show: false,'link': '/teamium/show/show-edit', 'pathVariable': 1, 'pathAttributes': ['showId'] },
      // { 'title': 'Project', show: false,'link': '/teamium/show/show-projects', 'pathVariable': 1, 'pathAttributes': ['showId'] },
      // { 'title': 'Scheduling',show: false,},
    ]
  },
  {
    'title': 'Projects', show: false, icon: "far fa-folder", 'defaultUrl': '/teamium/project/project-list', 'defaultSubmenuUrl': '/teamium/project/project-budgeting', 'pathVariable': 1, 'children': [
      {'title': 'Request', show: false,  'link': '/teamium/project/project-budgeting', 'pathVariable': 1, 'pathAttributes': ['budgetId'], 'defaultSubmenuUrl': '/teamium/project/budgeting/call-sheet', 'subPathAttributes': ['budgetId'],'disabled':false },
      {'title': 'Booking', show: false, 'link': '/teamium/project/project-booking', 'pathVariable': 1, 'pathAttributes': ['projectId'], 'defaultSubmenuUrl': '/teamium/project/booking/call-sheet', 'subPathAttributes': ['projectId'],'disabled':false},
      // { 'title': 'Wall', show: false, 'link': '/teamium/project/project-wall', 'pathVariable': 1, 'pathAttributes': ['budgetId'] },
      // { 'title': 'Notes', 'link': '/teamium/project/project-note', 'pathVariable': 1, 'pathAttributes': ['budgetId'] },


      // {
      //   'title': 'Procurement',show: false,  'link': '/teamium/project/project-procurement', 'pathVariable': 1, 'pathAttributes': ['projectId'], 'defaultSubmenuUrl': '/teamium/project/purchase-order', 'subPathAttributes': ['projectId'], 'children': [
      //     { 'title': 'Purchase Order', 'link': '/teamium/project/purchase-order', 'pathVariable': 1, 'pathAttributes': ['projectId'] }
      //   ]
      // },
    //   { 'title': 'Invoicing', 'link': '/teamium/project/project-invoice', 'pathVariable': 1, 'pathAttributes': ['projectId'], 'defaultSubmenuUrl': '/teamium/project/project-invoice/invoice-details', 'subPathAttributes': ['projectId'], 'children': [
    //     { 'title': 'Invoice', 'link': '/teamium/project/project-invoice/invoice-details', 'pathVariable': 1, 'pathAttributes': ['projectId'] }
    //   ]
    // },
    // { 'title': 'Financial Dashboard', show: false, 'link': '/teamium/project/project-financial', 'pathVariable': 1, 'pathAttributes': ['budgetId'] },
      
    ]
  },
  {
    'title': 'Scheduler',show: false, icon: "far fa-calendar-o", 'defaultUrl': '/teamium/schedule/group-agenda', 'defaultSubmenuUrl': '/teamium/schedule/group-agenda', 
    'children': [
      // { 'title': 'Resources', show: false, link: '/teamium/schedule/group-agenda' },
      // // { 'title': 'Project',show: false, link: '/teamium/schedule/project-event' },
      // // { 'title': 'Show',show: false, link: '/teamium/schedule/schedule-program', 'pathVariable': 1, 'pathAttributes': ['showId']},
      // { 'title': 'Roster',show: false, link: '/teamium/schedule/group-schedule' },
      // { 'title': 'Events',show: false, link: '/teamium/schedule/event-cal' },
      // { 'title': 'Planning',show: false, link: '/teamium/schedule/planning-view' },
    ]
  },
  {
    'title': 'Resource', show: false, icon: "fas fa-video", 'defaultUrl': '/teamium/equipment/equipment-list', 'defaultSubmenuUrl': '/teamium/equipment/equipment-details', 'children': [
      { 'title': 'Edit',show: false, 'link': '/teamium/equipment/equipment-details', 'pathVariable': 1, 'pathAttributes': ['equipmentId'] },
      // { 'title': 'Package',show: false, 'link': '/teamium/equipment/equipment-package', 'pathVariable': 1, 'pathAttributes': ['equipmentId'] },
      // { 'title': 'Check-In',show: false, },
      // { 'title': 'Maintenance',show: false, }
    ]
  },
  {
    'title': 'Personnel', show: false, icon: "far fa-user", 'defaultUrl': '/teamium/personal/staff-list', 'defaultSubmenuUrl': '/teamium/personal/staff-details', 'pathVariable': 1, 'children': [
      { 'title': 'Edit',show: false, 'link': '/teamium/personal/staff-details', 'pathVariable': 1, 'pathAttributes': ['staffId'] },
      // { 'title': 'Show Reel',show: false, 'link': '/teamium/staff-show-reel', 'pathVariable': 1, 'pathAttributes': ['staffId'] },
      // { 'title': 'Contract'},
      // { 'title': 'Expenses', show: false,'link': '/teamium/personal/staff-expenses', 'pathVariable': 1, 'pathAttributes': ['staffId'] },
      // { 'title': 'Leave Management', show: false,'link': '/teamium/personal/staff-leave', 'pathVariable': 1, 'pathAttributes': ['staffId'] },
      // { 'title': 'Time Report', show: false,'link': '/teamium/personal/time-report', 'pathVariable': 1, 'pathAttributes': ['staffId'] },
    ]
  },
  // {
  //   'title':'Contract', show: false, icon: "fas fa-file-signature", 'defaultUrl': '/teamium/contract-list', 'defaultSubmenuUrl': '/teamium/contract-list', 'children': [ 
  //   ]
  // },
  // {
  //   'title': 'Supplies', show: false, icon: "fas fa-cart-plus", 'defaultUrl': '/teamium/supply', 'defaultSubmenuUrl': '/teamium/supply', 'children': [
  //   ]
  // },
  {
    'title': 'Asset', show: false, icon: "far fa-caret-square-right", 'defaultUrl': '/teamium/capital/asset', 'defaultSubmenuUrl': '/teamium/capital/asset', 
    'children':[
      { 'title': 'Edit', show: true,'link': '/teamium/capital/edit-asset','pathVariable': 1, 'pathAttributes': ['assetId']},
      { 'title': 'Shipping', show: true},
 //     { 'title': 'Batch', 'link': '/teamium/batch'},
    ]
  },
  {
    'title': 'Client', show: false, icon: "fas fa-users", 'defaultUrl': '/teamium/client/clientList', 'defaultSubmenuUrl': '/teamium/client/clientList', 'children': [
    ]
  },
  {
    'title': 'Notifications', show: true, icon: "far fa-bell", 'defaultUrl': '/teamium/notification/notification-builder', 'defaultSubmenuUrl': '/teamium/notification/notification-builder', 'children': [
    ]
  },
  {
    'title': 'Report', show: false, icon: "fas fa-chart-line", 'defaultUrl': '/teamium/record/report', 'defaultSubmenuUrl': '/teamium/record/report-edit', 'children': [
      // { 'title': 'Edit', show: true,'link': '/teamium/record/report-edit' }
    ]
  },
  {
    'title': 'Settings', show: false, icon: "fas fa-cog", 'defaultUrl': '/teamium/vendor/vendors', 'defaultSubmenuUrl': '/teamium/vendor/vendors', 'children': [

    ]
  },
]