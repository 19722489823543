import { Injectable, OnInit } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from '../../../../node_modules/rxjs';
import { SharedService } from './shared-service/shared.service';
import { filter } from 'rxjs/operators'
import { Router,NavigationEnd  } from '@angular/router';
import { CommonUtilService } from './common-util-service';
import { TranslateService } from '@ngx-translate/core';
import { UserRoleService } from './user-role.service';

export interface UserDetailsData {
  timeZone:'UTC'
}

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
public ud:UserDetailsData={
  timeZone:'UTC'
}

  loggedInUserDetails: any;
  private fetched = false;
  private funcs = [];
  constructor(private httpService: HttpService,private translateService:TranslateService,private userRoleService: UserRoleService,private sharedService: SharedService,private commonService:CommonUtilService,private router:Router) {
    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if ( event.id === 1 && event.url === event.urlAfterRedirects && window.location.pathname != '/signin' && window.location.pathname != '/reset-password') {
        this.fetchUserDetails();
      }
    })

   

  }


  isValidateUserRoleForModule(roleName: string, cb) {
    this.getLoggedInUser((res) => {
      return cb(res.role.some(role => role.roleName === roleName))
    })
  }

  getLoggedInUser(cb) {
    if (!this.loggedInUserDetails) {
      this.httpService.callApi('getLoggedInUser', {}).subscribe((response) => {
        this.loggedInUserDetails = response;
        this.loggedInUserDetails.locale = this.getLocaleByLanguage( this.loggedInUserDetails.uiLanguage);        
        cb(this.loggedInUserDetails);
      }, (error) => {
        
      })
    } else {
      cb(this.loggedInUserDetails);
    }

  }


update(res){
  this.ud.timeZone = res.userSettingDTO.timezone;
}

  fetchUserDetails(){
    let userID =  sessionStorage.getItem("userId")
    this.httpService.callApi('getLoggedInUser',{}).subscribe((response) => {
     
        this.sharedService.setLoggedInUserData(response);
        this.sharedService.setFcmToken();
  
        this.fetched = true;
        this.update(response);
        this.loggedInUserDetails = response;
        this.useLanguage(this.loggedInUserDetails.uiLanguage ? this.loggedInUserDetails.uiLanguage : 'English')
        this.loggedInUserDetails.locale = this.getLocaleByLanguage( this.loggedInUserDetails.uiLanguage);
        this.getFilters()
      
     
  
      
    }, (error) => {
      
    })
  }

  logout() {
    // this.doOffline();
    this.setuserFiltersLogout()
    this.httpService.callApi('logout', {}).subscribe((response) => {
      sessionStorage.clear();
      sessionStorage.clear();
   
      this.sharedService.kanbanOffSet = undefined;
      this.sharedService.kanbanData = undefined;
      this.sharedService.setkanbanDoneData(undefined);
      this.sharedService.kanbanDoneOffSet = 0;
      this.sharedService.setkanbanTODoData(undefined);
      this.sharedService.kanbanToDoOffSet = 0;
      this.sharedService.setProjectInfo([]);
      this.sharedService.multipleCallRemover([],'personalList');
      this.sharedService.multipleCallRemover([],'equipmentList');
      let blankArr = []
      this.sharedService.setAllKitListByLoggedInUser(blankArr);
      this.userRoleService.clear();
   
    }, error => {
      
      sessionStorage.clear();
      sessionStorage.clear();

      this.userRoleService.clear();
    });
    this.httpService.logout();
    this.userRoleService.logout();
  

  }

  async setuserFiltersLogout() {
    try {
  
      let userid = sessionStorage.getItem('userId');
      let filterData:any = this.sharedService.getuserFilters()
      if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
        if(filterData.values != null){
          for (const property in filterData.values) {
            for (let i = 0; i < filterData.values[property].length; i++) {
             if(filterData.values[property][i].filterId != undefined){       
              delete filterData.values[property][i].filterId
             }
             if(filterData.values[property][i].id != undefined){
              delete filterData.values[property][i].id
             }
             console.log(filterData.values[property])
            //  return
             if(filterData.values[property][i].recordKey == "pendingBooking"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dateRangeFeild"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "tableTimeFlag"){
              continue
             }
             if(filterData.values[property][i].recordKey == "timeFormatUser"){
              continue
             }

             if(filterData.values[property][i].recordKey == "dateFormatUser"){
              continue
             }
             if(filterData.values[property][i].recordKey == "PersistantRange"){
              continue
             }
             if(filterData.values[property][i].mapId == "CUSTOMBORAD"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerTimeZone"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerLabel"){
              continue
             }
             if(filterData.values[property][i].recordKey == "statusTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "extendedContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "moveEvent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "resizeEvent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "weekendContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "buissnessHour"){
              continue
             }
             if(filterData.values[property][i].recordKey == "rosterContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedKitId"){
              continue
             }
             if(filterData.values[property][i].recordKey == "board"){
              continue
             }
             if(filterData.values[property][i].recordKey == "list"){
              continue
             }
             if(filterData.values[property][i].recordKey == "kanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "router"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerClient"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerEquipment"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPersonal"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerSideNav"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarViewScheduleProject"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPkanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectCard"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dSubclient"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dDate"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dProjectOwner"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dLocation"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dBuissnessUnit"){
              continue
             }
             if(filterData.values[property][i].recordKey == "LISTFILTERARR"){
              continue
             }
             if(filterData.values[property][i].recordKey == "displayView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "showChartR"){
              continue
             }
             if(filterData.values[property][i].recordKey == "lastDateRange"){
              continue
             }
             if(filterData.values[property][i].recordKey == "reportView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedPeriod"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedTimeframe"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedYearOption"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedMonth"){
              continue
             }
             if(filterData.values[property][i].recordKey == "isPeriodOrTimeframeChanged"){
              continue
             }
             if(filterData.values[property][i].recordKey == "isYearOrMonthChanged"){
              continue
             }
             if(filterData.values[property][i].recordKey == "orderForms"){
              continue
             }
             if(filterData.values[property][i].recordKey == "headerDview"){
              continue
             }
             if(filterData.values[property][i].recordKey == "showTx"){
              continue
             }
            
             filterData.values[property][i].recordValue = ""
              
            }
           
          }
        }
      }
  
      if (filterData?.staffMemberId == null) {
        filterData.staffMemberId = userid
      }
     
      const res: any = await this.commonService.setfilters(filterData)
    
    } catch (error) {
      console.log(error)
    }

  } 

  useLanguage(language: string) {
    switch (language) {
      case 'English':
        language = 'en';
        break;
      case 'French':
        language = 'fr';
        break;
      case 'German':
        language = 'de';
        break;
      case 'Arabian':
        language = 'Arabic';
        break;
       case 'Spanish':
        language = 'Spanish';
        break;
       case 'Italian':
        language = 'Italian';
        break;
       case 'Russian':
        language = 'Russian';
        break;
       case 'Indi':
        language = 'Indi';
        break;
      default:
        language = 'en';
    }
    this.translateService.use(language);
    sessionStorage.setItem("selectedLanguage", language);
  }



  call(fn: () => any) {
    if (!this.fetched) {
      this.funcs.push(fn);
    } else {
      fn();
    }
  }


  getLocaleByLanguage(lang) {

    if (lang==null || lang == undefined) 
    return"en-us";
    if (lang === "English")
      return "en-us";
    if (lang === "French")
      return "fr-fr";
    if (lang === "Italian")
      return "it-it";
    if (lang === "Arabian")
      return "en-us";
    if (lang === "Chinese")
      return "en-us";
    if (lang === "German")
      return "en-us";
    if (lang === "Spanish")
      return "en-us";

  }

  async getFilters(){
    try {
      let userid = sessionStorage.getItem('userId');
      const res:any = await this.commonService.getfilters(userid);
        let filterData = res;
        if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
          if(filterData.values != null){
            for (const property in filterData.values) {
              for (let i = 0; i < filterData.values[property].length; i++) {
               if(filterData.values[property][i].filterId != undefined){
                delete filterData.values[property][i].filterId
               }

               if(filterData.values[property][i].id != undefined){
                delete filterData.values[property][i].id
               }
                
              }
             
            }
          }
        }
       console.log(filterData)
      this.sharedService.setuserFilters(filterData)
      this.funcs.forEach(element => {
        element();
      });
      this.funcs = [];
    } catch (error) {
      console.log(error)
      this.funcs.forEach(element => {
        element();
      });
      this.funcs = [];
    }
}

}
