<div id="myModal" class="modal in confirmation-Modal" style="display: block;background: rgba(0, 0, 0, 0.6); z-index: 11500 !important; top: 54px;" >
    <div class=" modal-dialog delete-modal-width">
       
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body pt-5">
                    <div class="row mx-0 text-center mb-5">
                        <div class="t-delete-icon mx-auto">
                            <i class="fas fa-exclamation"></i>
                        </div>
                    </div>
                    <div class="create-personnel">
                        <div class="inner-content">
                          <h2 class="font-roboto-cad txt-light-gog text-center">Access Denied</h2>
                          <h4 class="confirmationText font-roboto-cad font-20 text-center txt-light-gog">
                            Dear User, unfortunately, we are unable to access the platform at the moment. We sincerely apologize for the inconvenience. Please reach out to your manager for further assistance. Thank you for your patience.
                          </h4>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center pb-5">
                  <button
                  class="btn btn-first btn-confirm bg-red font-12 text-uppercase txt-btn"
                  (click)="onLogout()"
                  style="background-color: #f44336 !important; padding: 12px 30px !important;"
                >
                  Go Back
                </button>
                </div>
            </div>
    </div>
  </div>