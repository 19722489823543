import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ElementRef, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '../../../node_modules/@angular/forms';
import { HttpService } from '../core/services/http.service';
import { ChatService } from '../core/services/chat.service';
import moment from 'moment-timezone';
import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { truncate } from 'fs';
import { ToastrTranslateService } from '../core/services/toastr-translate.service';
import { UserRoleService } from '../core/services/user-role.service';
import { UserPreferencesService } from '../core/services/user-preferences.service';
import { SettingsService, SettingsData } from 'src/app/core/services/settings.service';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import {NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilService } from '../core/services/common-util-service';
import { SharedService } from '../core/services/shared-service/shared.service';
import { UrlsService } from '../core/services/urls.service';
import { Router,ActivatedRoute, Params,RouteConfigLoadStart, } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ProjectListService } from '../core/services/project-list/project-list.service';
import { BookingRequestService } from '../core/services/booking_request/booking-request.service';
import { FrameioService } from '../core/services/frameio/frameio.service';
import { ThemeService } from '../core/services/theme/theme.service';

declare var $:any;



declare var zE: any;
  

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mainHeader', { static: true }) mainHeaderDiv: ElementRef;
  @ViewChild('contactSearch') contactSearchDiv: ElementRef;
  @ViewChild('contactList') contactListDiv: ElementRef;


  formats: any = ['Months/Days', 'Week/Days', 'Week/Half Days', 'Week/4 Hours', 'Week/Hours', 'Days/Hours/30Min', 'Days/Hours/15Min','Days/Hours/5Min'];
  dateRange: any = ['Today','Week','Month','Year'];

  public liClicked: boolean = false;
  public showLogot: boolean = false;
  public notificationClick: boolean = false;
  messageListClick: boolean = false;
  addedAttachmentFileName:any = null;
  statusheadingName:any = 'All';
  completedFilterUser:any = null;
  attachments:any=[];
  openHelp:boolean = false;
  leftDistance:any = '573px';
  skypeReply:boolean = false;
  googmeetReply:boolean = false;
  teamsReply:boolean = false
  showMarketplace:boolean = false;
  showStatus:boolean = false;
  showAttachment:boolean = false;
  openTaskModal:boolean = false;
  marketPlaceForm: any;
  globalSub: any;
  selectedNotificationmodal:any=-1;
  allowedExtensionsReel = ['pdf','jpg','png','xlsx','xlsm','xlsb','xltx','docx','dot','dotx'];
  invalidDocFormatError:any =null;
  docFile:any=null
  addedPictureNameMessage:any=null;
  fileExtenstion:any=null;
  docForm: FormGroup;
  notesSearch:any=null
  taskSearch:any=null

  declineReason:any=null;
  loggedInUserData: any = null;
  projectId:any=null
  taskDesc:any = null;
  taskDate:any = new Date();
  taskStatus:any = 'To Do';
  taskSeverity:any = '1';
  taskAssignee:any = null;
  recordId:any = null;
  firstName: any;
  companyDropdown:any
  languages: any = [];
  public showUserList: boolean = true;
  public userList: any = [];
  public userProjectList: any[] = [];
  public userNotificationList: any = [];
  public allOnlineUsersList: any = {};
  notificationsObject: any = {};
  mainNotificationCount: any = 0;
  personalcompany:any=null;
  to = '';
  from = '';
  msg = '';
  toUserObject = {};
  allMessageList: any = []
  searchUserKeyword: string = '';
  showPendingList:any= false;
  searchResults:any=[];
  taskName:any=null;
  
  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredOptions: Observable<[]>;
  allListTags:any=[];
  assignedTags:any=[];
  tagsInput: any = new FormControl(null);
  selectable = true;
  removable = true;
  personnelList:any=[];

  clientListSubject: Subject<any> = new Subject<any>();




  showProjectTheme:any= false;
  taskModalFlag:any= false;
  incidentModalFlag:any= false;
  isUserTyping: boolean = false;
  timeZoneList = new Array<any>();
  userRolesList = [];
  lastMessageSentTime: any;
  showDigitalSignatureModal: boolean = false;
  editable: boolean = false;
  allowedExtensionsForPhoto = ['jpg', 'jpeg', 'png'];
  @ViewChild('inputImage', { static: true }) inputImage: ElementRef;
  pictureUrl: string;
  showResetPasswordModal: boolean = false;
  blockedPanel:boolean=false;
  countNotification: any = 0;
//  pageNo:number=0;
  noMoreNotifications: boolean = true;
  lowestNotificationId: number = 0;
  expandedNotificationId = 0;
  selectedTab = 1; // 1 for user chat, 2 for project chat
  loggedInUserTimeZone:any
  projectNotes:boolean = false;
  projectNoteList: any = [];
  derivedSettings:any = {};
  outlookSync:any = false;
  colorbox:any=false
  settings:SettingsData = {};
  loggedInUserDetails:any =null;
  viewFunctionList:Boolean;
  tableDate:Boolean;
  ProjectDetailOpen:Boolean;
  googleSync:any=false
  notificationEnabled:any=true;
  googleTheme:any=null
  selectedColor:any='#5484ed'
  showGlobalModal:boolean = false;
  toggleElement:boolean = false;
  toggleElement1:boolean = false;
  searchText:string | any;
  filterData:any=[]

  userPrefrenceList = [
    'User Agenda',
    'Project',
    'Project Dashboard',
    'Project Desk',
    'Schedule Pool',
    'Schedule Project',
    'Schedule Roster',
    'TX Dashboard',
    'Warehouse',
    'Work Order Dashboard',
  ];

  marketPlaceId: any;
  showSupport: boolean = false;
  clientList: any = [];
  availableSub:any;
  sortBy: string;
  sortValue: number = -1;
  loginEmail:any
  chatBox:boolean = false;
  public textArea: string = '';
  public isEmojiPickerVisible: boolean;
  selectedStep: number = 1;
  searchValChanged: Subject<any> = new Subject<any>();
  searchTextchanges: Subject<any> = new Subject<any>();

  selectedNameTag:any=null;
  projectArr:any=[];
  themeData:any;


  constructor(private matIconRegistry: MatIconRegistry,private route:ActivatedRoute,private themeService:ThemeService,private frameioService:FrameioService,private projectSerice:ProjectListService,private bookingService:BookingRequestService,private domSanitizer: DomSanitizer,private renderer: Renderer2,private urlService:UrlsService, private dataService: DataService,private router:Router ,private httpService: HttpService, private formBuilder: FormBuilder, private chatService: ChatService, private translateService: TranslateService, private toastr: ToastrTranslateService ,
    private userPrefService: UserPreferencesService,private userRoleService: UserRoleService,private settingsService: SettingsService,private ud :UserDetailsService, configNgb: NgbAccordionConfig,private commonUtilService:CommonUtilService, private cd:ChangeDetectorRef
    ,private sharedservice:SharedService ) {
    this.marketPlaceForm = this.createMarketPlaceFormGroup();
    this.derivedSettings = this.settingsService.derivedSettigs;
    this.settings = this.settingsService.settings;
    configNgb.closeOthers = true;
    this.themeData = this.themeService
    this.matIconRegistry.addSvgIcon('slack',this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/Slack_icon.svg')); 




 

  this.searchValChanged.pipe(debounceTime(500)).subscribe((value) => {
    this.searchTags(value);
  });

  this.searchTextchanges.pipe(debounceTime(1000)).subscribe((value) => {
    // if(){
      this.searchCompValue(value);
    // } 
  });


  
    
    //  this.renderer: Renderer2 
  }

  

  getAllTimeZone() {
    this.httpService.callApi('getAllTimeZone', {}).subscribe((response) => {
      this.sharedservice.setAllTimeZone(response);
    }, error => {
    });
  }

  getAllClients() {
    this.httpService.callApi('getAllClientsBasic', {}).subscribe((response) => {
     this.sharedservice.setAllClient(response);
    }, error => {
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileExtension = file.name.split('.').pop().toLowerCase();
      
        if (this.isInArray(this.allowedExtensionsReel, fileExtension)) {
          this.invalidDocFormatError = null;
          this.docFile =  file;
          console.log(this.docFile)
         this.addedPictureNameMessage=`Sucessfully added ${file.name}`;
         this.fileExtenstion =  fileExtension;
        }
        else {
          this.docFile =  null
          this.addedPictureNameMessage = null
          this.fileExtenstion =  null;
          this.invalidDocFormatError = "Only pdf,jpg,png,xlsx,xlsm,xlsb,xltx,docx,dot,dotx format allowed for attachment!!"
        }
      
   

    }

  }


  checkforLocalAndSandbox(){
    if(window.location.hostname == 'sandbox.teamium.net' || window.location.hostname == 'localhost' ){
      return true
    } else {
      return false
    }
  }

  getCompanyDropdownForRole(){
    this.httpService.callApi('getCompanyDropdownForRole', {}).subscribe((response => {
      this.sharedservice.setAllCompanyDropdownForRole(response);
    
    
    }), (error) => {
      // this.toastr.warning(error.error.message, 'Personnel');

    })
  }  



  appNgOnInit() {

   this.globalSub =  this.sharedservice.globalKeywords$.subscribe(() => {
      this.getAllKeywordstags()
    });


    this.docForm = this.formBuilder.group({
      validate: [null],
      url: [null, Validators.required,Validators.pattern('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?|(http://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?') ]
  });

    this.loggedInUserDetails = this.sharedservice.getLoggedInUserData()
    this.pictureUrl = this.loggedInUserDetails.photo ? this.loggedInUserDetails.photo.url : '';
    this.getNotificationCount();
    this.getLoggedInUserNotification(0);
    // this.sharedservice.theme.subscribe((theme: string) => {
    //   this.changeInTheme();
    // });

    // setTimeout(() => {
    // this.selectaTeamFrameIO()
      
    // }, 100);

    

    this.getAllProjectDataName();

    this.getMarketPlaceByUserId()

    console.log(this.loggedInUserDetails)
    // this.availableSub = this.sharedservice.AllAvailableFunction$.subscribe(() => {
    //   this.getAllUserList();
    // });
   
    // this.chatService.uiControl().subscribe(msg => this.processChatUIControl(msg));
    this.existingDarkMode();
    this.viewFunctionList = this.loggedInUserDetails.bookingMenu;
    this.tableDate = this.loggedInUserDetails.ProjectTableStatus;
    this.ProjectDetailOpen = this.loggedInUserDetails.projectMenu;
    this.googleSync = this.loggedInUserDetails.googleSync;
    this.outlookSync = this.loggedInUserDetails.outlookSync;
    if(this.loggedInUserDetails.frameioUser != undefined && this.loggedInUserDetails.frameioUser != null ){
      if(this.loggedInUserDetails.frameioUser.access){
        this.getAccessTokenFrameio()
      }
    }
    if(this.googleSync){
      this.sharedservice.signIn()
    }
    if(this.outlookSync){
      // this.sharedservice.signInWithOutlook()
    }

      this.getPersonalls();


    this.getAllTimeZone();
    // this.getAllClients();
    // this.getCompanyDropdownForRole()

   
   

    this.selectedColor = this.loggedInUserDetails.googleTheme
    this.sharedservice.setgenericFunctionCatalog(this.loggedInUserDetails.bookingMenu);
    this.sharedservice.setProjectDetailOpen(this.loggedInUserDetails.projectMenu);
    this.getNotes()
    this.getAllUserTask();
    this.getAllUserIncident();
  }

  // changeInTheme() {
  //   if (!this.themeService.darkMode) {
  //     // this.graphColor = 'E0E0E0'
  //     this.headerColor = "#f1f1f1";
  //     this.alterDark = "#FBFCFD";
  //     this.alterMedium = "#F0F2F3";
  //     this.alterLight = "#E0E4E6";
  //     this.darkMode = false;
  //     this.fontColor = "#767676";
  //   } else {
  //     // this.graphColor = '#616161'
  //     this.headerColor = "#222a38";
  //     this.alterDark = "#364b57";
  //     this.alterMedium = "#415669";
  //     this.alterLight = "#6a7f8f";
  //     this.darkMode = true;
  //     this.fontColor = "#A9A9A9";

  //     // this.showChartGraph()
  //   }
  //   this.scheduler?.control?.update();
  // }

  searchCompValue(value: string) {

    this.sharedservice.setHeaderValue(value);
  }

  applyFilter(event) {
      this.searchTextchanges.next(event.target.value);
    }
  



  async getAccessTokenFrameio(){
    try {
      let obj ={
        refreshToken:this.loggedInUserDetails.frameioUser.refreshToken
      }
      const res:any =  await this.commonUtilService.gerAccessTokenFrameio(obj)
      sessionStorage.setItem('frameioToken',res.access_token);
      this.loggedInUserDetails.frameioUser.refreshToken = res.refresh_token;
      this.sharedservice.setLoggedInUserData(this.loggedInUserDetails);
      this.saveRefreshTOken(res.refresh_token)
    this.frameioService.getUserAccountInfo()

    } catch (error) {
      console.log(error)
    }
  }

  async saveRefreshTOken(token){
    try {
      let obj:any = {
        "email": null,
        "password": null,
        "access": true,
        "newuser": true,
        "staffid":this.loggedInUserDetails.id,
        "refreshToken": token
      }
      const res:any = await this.commonUtilService.saveAccessTokenFrameio(obj)
      
    } catch (error) {
      console.log(error)
    }
  }

  saveAttachment(id:any) {
    if (this.docFile != null) {
      let body = new FormData();
      body.append('fileContent', this.docFile);
      body.append('attachmentType', 'Task');
      body.append('isFeedByUrl', 'false');
      body.append('discriminator', 'projecttask');
      body.append('extension', this.fileExtenstion);
      this.httpService.callApi("uploadAttachment", { body: body, pathVariable: id }).subscribe((respone) => {
      this.blockedPanel = false;
       
      }, (error) => {
        console.log(error)
      });
    } else {
    }
  }

  async getPersonals(id) {
        try {

          const res: any = await this.commonUtilService.getAllPersonelBasiById(id);
          return res
        } catch (error) {
          this.blockedPanel = false;
          
        }
      


  }

  async getPersonalls() {
    
    try {
      this.commonUtilService.isProcessinggetAllPersonelProjectById = false;
      this.commonUtilService.requestQueuegetAllPersonelProjectById = [];
          const res: any = await this.commonUtilService.getAllPersonelProjectById();
        this.personnelList = [...res];
  
    } catch (error) {
      console.log(error)
    
    }
  }

  private _filter(name) {
    let filterValue = null;
    if (name.tagKeyword != undefined) {
      filterValue = name.tagKeyword.toLowerCase();
    } else {
      filterValue = name.toLowerCase();
    }

    let filter = this.allListTags.filter((option) =>
    option.tagKeyword.toLowerCase().includes(filterValue)
  );  

  filter =  filter.filter(el => el.visible ==  true)

  return filter
  }

   removeAssignedTags(tag) {
    try {
      this.assignedTags.splice(this.assignedTags.indexOf(tag), 1);
      this.searchTags(null)
    } catch (error) {
      console.log(error)
    }
  } 

  async searchTags(event: any){
    try {
      let tagsIdArr = []
      let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'UTC';
      if(event != null && event.option != undefined){
        const value = event.option.value;
        let index =  this.assignedTags.findIndex(el => el.tagKeyword.toLowerCase() == value.tagKeyword.toLowerCase());
        if (index == -1 ) {
          this.assignedTags.push(value);
          tagsIdArr = this.assignedTags.map(el => el.tagid)
         
        } else {
          this.toastr.warning("Tag Already Exists");
        }
      } else {
        tagsIdArr = this.assignedTags.map(el => el.tagid)
      }
      let obj:any = {}   
      if(tagsIdArr.length > 0){
        obj.tagsId = tagsIdArr.toString()
      } 

      
      if(this.selectedNameTag !=null && typeof this.selectedNameTag != 'object'){
        obj.name= this.selectedNameTag
      }
 
      const res:any = await this.commonUtilService.globalSearchApi(obj);
      this.searchResults = [];
    
      for (let i = 0; i < res.length; i++) {
        let obj:any={}
        if(res[i].id != undefined && res[i].title != undefined &&  res[i].resourceType == 'Project'){
          let keyword:any=[]
          if(res[i].recordGlobalKeywords != undefined && res[i].recordGlobalKeywords.length > 0){
            keyword = res[i].recordGlobalKeywords.filter(el => tagsIdArr.includes(el.tagid))
          }
          obj ={
            id:res[i].id,
            projectFlag:true,
            listKeyWordMatch:keyword,
            name: res[i].title,
            resourceType:res[i].resourceType,
            date: res[i].startDate != null ? new Date(moment(res[i].startDate).tz(timezone).format("YYYY-MM-DD HH:mm:ss")) : null,
          }
          if(res[i].isBooked){
            obj.routerLink = `/teamium/project/project-booking/${res[i].id}`
          } else {
            obj.routerLink = `/teamium/project/project-budgeting/${res[i].id}`
            
          }

          this.searchResults.push(obj);
        }

        if(res[i].id != undefined &&  res[i].resourceType == 'Staff'){
          let keyword:any=[]
          if(res[i].recordGlobalKeywords != undefined && res[i].recordGlobalKeywords.length > 0){
            keyword = res[i].recordGlobalKeywords.filter(el => tagsIdArr.includes(el.tagid))
          }
          obj ={
            id:res[i].id,
            projectFlag:false,
            listKeyWordMatch:keyword,
            name: res[i].staffName,
            date: null,
            resourceType:res[i].resourceType
          }
         
            obj.routerLink = `/teamium/personal/staff-details/${res[i].id}`
          

          this.searchResults.push(obj);
        }
      }
    

    } catch (error) {
      this.toastr.warning(error.error.message)
      console.log(error)
    }
  }

  goToDetailsFromSearch(link: any,id) {
    let pathVariable: any = new Array();
    this.showGlobalModal = false
    pathVariable.push({ projectId: id });
    this.router.navigate([link]);
  }

  
  goToDetailsFromSearchP(link: any,id) {
    let pathVariable: any = new Array();
    this.showGlobalModal = false
    pathVariable.push({ staffId: id });
    // this.dataService.addPathvariables(pathVariable);
    this.router.navigate([link]);
  }

  checkforProdi(){
    if( window.location.hostname == 'prodigious.teamium.net' ){
      this.leftDistance = '473px';
      return true
     } else {
      this.leftDistance = '573px';

       return false
     }
 }

  ngOnInit() {

    this.router.events.subscribe((event:any)=> {
      this.notesSearch = null;
      this.searchTextchanges.next(this.notesSearch)
  
     
  });



    this.sharedservice.chatBox$.subscribe(() => {
       document.getElementById("notify").click()
    });
    this.settingsService.call(() => {
        this.userRoleService.call(() => {
          this.ud.call(() => {
            this.appNgOnInit();
          });
        });
    });
    this.sort(this.sortBy);  
    this.sharedservice.notificationRecieved$.subscribe(() => {
      this.userNotificationList = []

      this.getNotificationCount();
      this.getLoggedInUserNotification(0);
    });

    this.sharedservice.notificationFlag$.subscribe(() => {
      this.userNotificationList = []

      this.getNotificationCount();
      this.getLoggedInUserNotification(0);
    });

    this.filteredOptions = this.tagsInput.valueChanges.pipe(
      startWith(""),
      map((name) => (name ? this._filter(name) : this.allListTags.filter(el => el.visible ==  true).slice()))
    );
  this.tagsInput.valueChanges.subscribe((val)=> {
    this.selectedNameTag =  val;
    this.callSerachTagsFn(null)
  } );
  }

  callSerachTagsFn(event){
    this.searchValChanged.next(event);
  }
  
  setSelectedStep(stepNumber: number) {
    this.selectedStep = stepNumber;
  }

  public addEmoji(event) {
    this.textArea = `${this.textArea}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  removeTags(str) {
    if ((str === null) || (str === ''))
      return;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  getShortName(fullName) {
    if(fullName != undefined){
      return fullName
      .split(" ")
      .map((n) => n[0])
      .join("");
    }
   
  }

  sort(sortBy: string) {
    if (this.sortBy != sortBy) {
      this.sortValue = -1;
    }
    this.sortBy = sortBy;
    this.sortValue *= -1;
  }
  // setFunctionValue(){
  //   if(this.sharedservice.getgenericFucntionCatalog() != undefined){
  //     this.viewFunctionList = this.sharedservice.getgenericFucntionCatalog();
  //   }

  // }

  existingDarkMode(){
    this.blockedPanel = false;
    var darkMode  = this.loggedInUserDetails.darkMode;
      if(darkMode){
        this.themeService.setDarkMode()
      } else {
        this.themeService.setLisghtMode()
      }
  }



  ngAfterViewInit() {
  }



  getNotificationCount(){
    this.httpService.callApi("notificationCount",{}).subscribe((response) => {
      this.countNotification = response;
    })
  }

  show() {
    this.notificationClick = !this.notificationClick;
    if (this.notificationClick == false) {
      this.showUsersList();
    }
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    if(this.availableSub != undefined && this.availableSub.unsubscribe() != undefined){
      this.availableSub.unsubscribe();
    
    }

    if (this.globalSub != undefined && this.globalSub.unsubscribe() != undefined) {
      this.globalSub.unsubscribe();
    }
    
  }



  createMarketPlaceFormGroup(): FormGroup {
    return this.formBuilder.group({
      globalResource: [false],
      globalPersonnel: [false]
    })
  }



  openProfile(){
    this.router.navigate(['teamium/user/user-profile']);
  }



   getLoggedInUserNotification(lowestNotificationId:number){
   let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'Asis/Calcutta';
    this.httpService.callApi('getLoggedInUserNotificationList', {pathVariable: String(lowestNotificationId)}).subscribe((response) => {
      response.forEach( async x =>{
        if(x.requesterID != undefined && x.requesterID != null ){
          let personalData:any = await this.getPersonals(x.requesterID) 
          if(personalData != undefined && personalData != null){
            x.requesterName =  personalData.fullName
            if (personalData.userSettingDTO != undefined && personalData.userSettingDTO.staffTheme != undefined) {
              x.staffTheme = personalData.userSettingDTO.staffTheme;
              x.color = this.setContrast(personalData.userSettingDTO.staffTheme);
            }
          } else {
            x.requesterName =  'TM User'
          }
        } else {
          x.requesterName =  'TM User'
        }
         x.displayTime = moment(x.notificationTime).tz(timezone).format('YYYY-MM-DD HH:mm:ss')});
      this.userNotificationList = this.userNotificationList || [];
      if (response.length > 0) {
        this.lowestNotificationId = response[response.length-1].id;
        this.noMoreNotifications = false;
        if (this.userNotificationList) {
          //response.forEach(x => this.userNotificationList.push(x));
          this.userNotificationList.push(...response);
        } else {
          this.userNotificationList = response;
        }
      } else {
        this.noMoreNotifications = true;
        // Disable see more
        
      }
      
    }, error => {

    });
  }

  hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    // return {r, g, b} // return an object
    return [r, g, b];
  };

  setContrast(hexCode) {
    if (hexCode == undefined || hexCode == null  || hexCode == '' || !hexCode.toString().includes("#")) {
      return;
    }
    let rgb: any;
    if (hexCode.includes("#")) {
      rgb = this.hex2rgb(hexCode);
    } else {
      return "black";
    }
    const brightness = Math.round(
      (parseInt(rgb[0]) * 299 +
        parseInt(rgb[1]) * 587 +
        parseInt(rgb[2]) * 114) /
        1000
    );
    const textColour = brightness > 125 ? "black" : "white";
    return textColour;
  }



  seeMoreNotifications($event) {
    $event.stopPropagation();
    this.getLoggedInUserNotification(this.lowestNotificationId);
  }

  openCommentModal($event,notificationId){
    this.selectedNotificationmodal = notificationId
    var value = notificationId.toString()
    setTimeout(() => {
      $('#'+value).modal('show');
     }, 10);

     $event.stopPropagation();
  }

  async acceptOrdeclinereason(notificationId:any,bookingStatus){
    try {
      let notificationIndex = this.userNotificationList.findIndex(el => el.id == notificationId)
      let obj:any = this.userNotificationList[notificationIndex]
      if(notificationIndex != -1){
        if(bookingStatus == 'Confirm'){
          this.userNotificationList[notificationIndex].taskStatus = bookingStatus
          obj.taskStatus = bookingStatus
        } else {
          if(this.declineReason == null || this.declineReason == ''){
            this.toastr.warning("Decline Reason is mandatory")
            return
          }
          this.userNotificationList[notificationIndex].taskStatus =  bookingStatus
          obj.taskStatus = bookingStatus
          obj.message = this.declineReason
        }
      } else {
        this.toastr.warning("Invalid Notification")
        return
      }
      const res:any = await this.commonUtilService.acceptOrdeclinereason(obj);
      this.declineReason = null
      var value = notificationId.toString()
      setTimeout(() => {
        $('#'+value).modal('hide');
       }, 10);
    } catch (error) {
      console.log(error)
    }
  }


  deleteNotificationById($event, index: number, notification:any){
    let notificationId = notification.id;
  
    $event.stopPropagation();
    this.httpService.callApi('deleteNotificationById',{pathVariable:+notificationId}).subscribe((response) => {
      if (!notification.readStatus) {
        this.countNotification--;
      }
      this.userNotificationList.splice(index, 1);
      // if (this.userNotificationList.length < 5) {
      //   this.pageNo = 0;
      // }
    
    }, error => {
      
    });
  }

  deleteNotificationAll($event ){
   
    $event.stopPropagation();
    this.messageListClick = false;
    for (let i = 0; i < this.userNotificationList.length; i++) {
      let notificationId =  this.userNotificationList[i].id;
      this.httpService.callApi('deleteNotificationById',{pathVariable:+notificationId}).subscribe((response) => {
        this.countNotification = 0;
      this.userNotificationList = [];
    
    }, error => {
      this.toastr.warning(error.error.message)
    });
    }

  
  }

  updateNotificationStatus($event, notificationData:any) {
    $event.stopPropagation();
    let id = notificationData.id;
    this.expandedNotificationId = id;
    this.httpService.callApi('updateNotificationStatus',{pathVariable:id}).subscribe((response) => {     
        if (!notificationData.readStatus) {
          this.countNotification--;
        }
        notificationData.readStatus = true;
      }, error => {
        
      });
  }

  // async getAllUserList(){
  //   let count = 1;
  //   if (this.sharedservice.getAllAvailableFunction().length != 0) {
  //     this.userList = this.sharedservice.getAllAvailableFunction();
  //     this.userList.forEach(x => {x.user_type = 1; x.index = count++;});
  //     this.sortUserList();
  //     this.getAllUserProjects();
  //   }else {
  //     try {
  //       const res: any = await this.commonUtilService.getAllAvailableFunction();
  //       if(res.length > 0){
  //       this.sharedservice.setAllAvailableFunction(res);
  //       }
  //     } catch (error) {
  //       
  //     }
  //   }
 
  //  }

   async getAllProjectDataName() {
    try {
      const resp: any = await this.projectSerice.getAllProjectDataName();
      this.projectArr = resp;
    } catch (error) {
      
    }
  }



  getAllUserProjects = () => {
    let count = this.userList.length + 2;
    this.httpService.callApi('getUserProjects', {}).subscribe((response) => {
     
      response = response || [];
      const currentIds = this.userList.map(x => x.id);
      let currentIndex = 1;
      const _getNextId = () => {
        for( ; ; currentIndex++) {
          if (currentIds.indexOf(currentIndex) < 0) return currentIndex++;
        }
      };
      response.forEach(x => {
        x.index = count++;
        x.user_type = 2;
        x.firstName = x.title;
        x.lastName = '';
        // this.chatService.subscribeToProject(x.id);
      });
     
      this.userList.push(...response);
      this.sortUserList();
    }, error => {
      
    });
  }


  liClick() {
    this.liClicked = !this.liClicked;
    this.dataService.isWrapperActive = this.liClicked;
  }
  logout() {
    // this.doOffline();
    this.setuserFiltersLogout()
    this.httpService.callApi('logout', {}).subscribe((response) => {
      sessionStorage.clear();
      sessionStorage.clear();
    
      this.sharedservice.kanbanOffSet = undefined;
      this.sharedservice.kanbanData = undefined;
      this.sharedservice.setkanbanDoneData(undefined);
      this.sharedservice.kanbanDoneOffSet = 0;
      this.sharedservice.setkanbanTODoData(undefined);
      this.sharedservice.kanbanToDoOffSet = 0;
      this.sharedservice.setProjectInfo([]);
      this.sharedservice.multipleCallRemover([],'personalList');
      this.sharedservice.multipleCallRemover([],'equipmentList');
      let blankArr = []
      this.sharedservice.setAllKitListByLoggedInUser(blankArr);
      this.userRoleService.clear();
   
    }, error => {
      
      sessionStorage.clear();
      sessionStorage.clear();

      this.userRoleService.clear();
    });
    this.httpService.logout();
    this.userRoleService.logout();
  

  }

  async setuserFiltersLogout() {
    try {
  
      let userid = sessionStorage.getItem('userId');
      let filterData:any = this.sharedservice.getuserFilters()
      if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
        if(filterData.values != null){
          for (const property in filterData.values) {
            for (let i = 0; i < filterData.values[property].length; i++) {
             if(filterData.values[property][i].filterId != undefined){       
              delete filterData.values[property][i].filterId
             }
             if(filterData.values[property][i].id != undefined){
              delete filterData.values[property][i].id
             }
            //  return
             if(filterData.values[property][i].recordKey == "pendingBooking"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dateRangeFeild"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "tableTimeFlag"){
              continue
             }
             if(filterData.values[property][i].recordKey == "timeFormatUser"){
              continue
             }

             if(filterData.values[property][i].recordKey == "dateFormatUser"){
              continue
             }
             if(filterData.values[property][i].recordKey == "PersistantRange"){
              continue
             }
             if(filterData.values[property][i].mapId == "CUSTOMBORAD"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerTimeZone"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerLabel"){
              continue
             }
             if(filterData.values[property][i].recordKey == "statusTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "extendedContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "weekendContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "buissnessHour"){
              continue
             }
             if(filterData.values[property][i].recordKey == "rosterContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "board"){
              continue
             }
             if(filterData.values[property][i].recordKey == "list"){
              continue
             }
             if(filterData.values[property][i].recordKey == "kanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "router"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerClient"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerSettings"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerEquipment"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPersonal"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerSideNav"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarViewScheduleProject"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPkanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectCard"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerKitIndex"){
              continue
             }
             if(filterData.values[property][i].recordKey == "headerDview"){
              continue
             }
            if(filterData.values[property][i].recordKey == "projectGanttPersis"){
              continue
            }
            if(filterData.values[property][i].recordKey == "projectGanttViewType"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dSubclient"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dDate"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dTheme"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dProjectOwner"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dLocation"){
              continue
            }
            if(filterData.values[property][i].recordKey == "dBuissnessUnit"){
              continue
            }
           
            //  if(filterData.values[property][i].recordKey == "ProjectOwner"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "functionName"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "SubStatus"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "client"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Region"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Accountmanager"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Category"){  
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Country"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "City"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Client"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "ExternalReference"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "InternalReference"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Comment"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "projectTitle"){
            //   continue
            //  }
            //  if(filterData.values[property][i].recordKey == "Status"){
            //   continue
            //  }
            
             filterData.values[property][i].recordValue = ""
              
            }
           
          }
        }
      }
  
      if (filterData?.staffMemberId == null) {
        filterData.staffMemberId = userid
      }
     
      const res: any = await this.commonUtilService.setfilters(filterData)
    
    } catch (error) {
      console.log(error)
    }

  } 

  @HostListener('document:click', ['$event.target'])
  onClickedOutside(targetElement) {
    if (!targetElement.closest('#logOut')) {
      this.showLogot = false;
    }

    if (!targetElement.closest('#notificaton')) {
      this.notificationClick = false;
      // this.showUsersList();
    }
    if (!targetElement.closest('#message')) {
      this.messageListClick = false;
      this.expandedNotificationId = 0;
    }
    if (!targetElement.closest('#helpNotification')) {
      this.openHelp = false;
    }
    if (!targetElement.closest('#projectNotess')) {
      this.projectNotes = false;
    }
    if (!targetElement.closest('#statusMod')) {
      this.showStatus = false;
    }
    if (!targetElement.closest('#attachment')) {
      this.showAttachment = false;
    }
    if (!targetElement.closest('#showGlobalModal1')) {
      this.showGlobalModal = false;
    }
    if (!targetElement.closest('#chatBox')) {
      this.chatBox = false;
    }

  }

  showMessage() {
    this.showUserList = false;
  }

  redirectToLeaveManagement(personData:any){

    if(personData.projectId == 0 && personData.requesterID != undefined && personData.requesterID != null){
      this.sharedservice.fromHeader = true;
      this.router.navigateByUrl(`teamium/personal/staff-leave/${personData.requesterID}`);
    }
  }



  async goToDetails(projectId:any) {
    try {
      let userRole  =  JSON.parse(sessionStorage.getItem('userRole'))
      if(this.sharedservice.checkValueInArray(false,userRole,'projectsR',null)){
        this.toastr.warning("User does not have enough permission")
        return
      }
      this.blockedPanel = true;
      const res: any = await this.bookingService.getProjectContactsById(projectId.projectId);
      let project =  res;
        let pathVariable: any = new Array();
        if (project.booked == true &&  this.loggedInUserDetails.userType != 4) {
          this.blockedPanel = false;
          pathVariable.push({ projectId: project.id });
          this.router.navigate(["/teamium/project/project-booking/" + projectId.projectId]);
        } else {
          this.blockedPanel = false;
          this.router.navigate(["/teamium/project/project-budgeting/" + projectId.projectId]);
          pathVariable.push({ projectId: project.id });
        }
    } catch (error) {
      this.blockedPanel = false;
      
    }
  }





  showUsersList() {
    this.setMessageSeenByUserOnCloseChatBox(this.toUserObject['id']);
    this.timeoutFunction()
    this.toUserObject = {};
    this.showUserList = true;
  }

  getNotifyIndex(msgType, id) {
    let ret = null;
    this.userList.forEach((x, i) => {
      if (x.user_type === msgType && x.id === id) {
        ret = x.index;
      }
    });
    return ret;
  }

  getNotifyIndexForMsg(msg) {
    const msgType = msg.msg_type;
    const id = msgType === 1 ? msg.message_from : msg.message_to;
    return this.getNotifyIndex(msgType, id);
  }

  replyToTeams(){
    this.teamsReply = true;
    this.googmeetReply = false;
    this.skypeReply = false;

  }

  replyToGoogleMeet(){
    this.teamsReply = false;
    this.googmeetReply = true;
    this.skypeReply = false;

  }

  replyToSkype(){
    this.teamsReply = false;
    this.googmeetReply = false;
    this.skypeReply = true;

  }

  // connectMe = (userId) => {
  //   this.chatService.getConnect(userId).subscribe(res => {
  
  //     // detect whenever new message get
  //     if (res['type'] == 'getMessage') {
  //       let to = this.toUserObject['id'] || '';
  //       let from = this.from || '';

  //       // Updates to migrate to Java chat server
  //       res['data'].message_from = res['data'].from;
  //       res['data'].message_to = res['data'].to;
  //       res['data'].message = res['data'].msg;
  //       res['data'].message_send_time = res['data'].timestamp;

  //       // identify the message from and add message to that user chat box
  //       if (res['data'].msg_type === 1) {
  //         if ((res['data'].message_from == from && res['data'].message_to == to) || (res['data'].message_from == to && res['data'].message_to == from)) {
  //           this.allMessageList.push(res['data']);
  //           setTimeout(this.scrollBottom, 0);
  //         }
  //       } else {
  //         if (to === res['data'].message_to) {
  //           this.allMessageList.push(res['data']);
  //           setTimeout(this.scrollBottom, 0);
  //         } 
  //       }

  //       //  check notification count and last message for show notification
  //       if (res['data'].message_from != to && res['data'].message_from != from) {
  //         const notifyIndex = this.getNotifyIndexForMsg(res['data']);
  
  //         if (this.notificationsObject[notifyIndex]) {
  //           this.notificationsObject[notifyIndex] = {
  //             count: this.notificationsObject[notifyIndex]['count'] + 1,
  //             data: res['data'],
  //             lastUpdate: res['data'].message_send_time//new Date()
  //           }

  //         }
  //         else {
  //           this.notificationsObject[notifyIndex] = {
  //             count: 1,
  //             data: res['data'],
  //             lastUpdate: res['data'].message_send_time//new Date()
  //           }
  //         }
  //         this.updateMainNotificationCount();
  //       }

  //       try {
  //         this.lastMessageSentTime = res['data'].message_send_time;
  //         this.messageSendUserObject(res['data'].message_to);
  //       }
  //       catch (err) {
  //         
  //       }
  //     }

  //     if (res['type'] == 'getAllMessage') {
  
  //       this.allMessageList = res['data'] || [];
  //       this.allMessageList.forEach( x => {
  //         // Updates to migrate to Java chat server
  //         x.message_from = x.from;
  //         x.message_to = x.to;
  //         x.message = x.msg;
  //         x.message_send_time = x.timestamp;
  //       });
  //       setTimeout(this.scrollBottom, 0)
  //     }

  //     if (res['type'] == 'setAllMessageNotifications') {
  //       this.createNotificationObject(res['data']);
  //     }

  //     if (res['type'] == 'newConnection') {
  //       //this.allOnlineUsersList = res['data']['onlineUserList'] || {};
  //       this.allOnlineUsersList = {};
  //       res['data']['onlineUsers'].forEach(x => this.allOnlineUsersList[x] = x);
  
  //     }

  //     if (res['type'] == 'notification') {
  //       this.getRecentNotifications(this.lowestNotificationId);
  //     }

  //     if (res['type'] == 'getUserIsTyping') {
  //       if (res['data'].typing && res['data'].from == this.toUserObject['id']) {
  //         this.isUserTyping = true;
  //       }
  //       else {
  //         this.isUserTyping = false;
  //       }

  //     }

  //   }, err => {
  //     ;
  //   });
  // }

  private processChatUIControl(msg) {
    if (msg.command === "openProject") {
      this.openProjectChat(msg.args[0]);
    }
  }

  private openProjectChat(projectId: number) {
    
    const user = this.userList.filter(x => x.user_type === 2 && x.id === projectId);
    if (user.length === 1) {
      this.notificationClick = true;
      this.selectedTab = 2;
      this.onSelectFriend(user[0]);
    }
  }

  onSelectFriend = (friend) => {
    this.isUserTyping = false;
    this.toUserObject = friend || {};
    this.allMessageList = [];
    this.getAllMessage();
    //this.notificationsObject[friend.id] = null;//0;
    this.notificationsObject[this.getNotifyIndex(friend.user_type, friend.id)] = null;
    this.updateMainNotificationCount();
    this.showMessage();
    this.msg = "";
    this.setMessageSeenByUser();
    
  }

  setMessageSeenByUser = () => {
    let to = this.toUserObject['id'] || '';
    let from = this.loggedInUserDetails.id || '';
    if (to == '' || from == '') {
      return;
    }
    let data = {
      from: to,
      to: from
    };
    // this.chatService.setMessageSeenByUser(data);
  }

  setMessageSeenByUserOnCloseChatBox = (toId) => {
    let to = toId || '';
    let from = this.loggedInUserDetails.id || '';
    if (to == '' || from == '') {
      return;
    }

    let data = {
      from: to,
      to: from
    };
    // this.chatService.setMessageSeenByUser(data);
  }

  updateMainNotificationCount = () => {
    this.mainNotificationCount = 0;
    
    Object.keys(this.notificationsObject).forEach(key => {
      if (this.notificationsObject[key] && this.notificationsObject[key].hasOwnProperty('count'))
        this.mainNotificationCount += this.notificationsObject[key].count;
    })
    this.sortUserList();
    
  }

  sortUserList = () => {
    try {
      let userListWithNotification = [];
      let userListWithoutNotification = [];
      this.userList.forEach(user => {
        const notifyIndex = this.getNotifyIndex(user.user_type, user.id);
        if (this.notificationsObject[notifyIndex] && this.notificationsObject[notifyIndex]['count'] > 0) {
          user.lastUpdate = this.notificationsObject[notifyIndex]['lastUpdate'];
          userListWithNotification.push(user);
        }
        else {
          userListWithoutNotification.push(user);
        }
      })

      userListWithNotification.sort((a, b) => {
        let c: any = new Date(a.lastUpdate);
        let d: any = new Date(b.lastUpdate);
        return d - c;
      });


      let newUserList = [...userListWithNotification, ...userListWithoutNotification];
      let newUserListWithLastUpdate = [];
      let newUserListWithoutLastUpdate = [];
      newUserList.forEach(user => {
        if (user.lastUpdate) {
          newUserListWithLastUpdate.push(user);
        }
        else {
          newUserListWithoutLastUpdate.push(user)
        }
      })

      let updatedUserList = [...newUserListWithLastUpdate, ...newUserListWithoutLastUpdate];
      updatedUserList.sort((a, b) => {
        let c: any = new Date(a.lastUpdate);
        let d: any = new Date(b.lastUpdate);
        return d - c;
      });

      this.userList = updatedUserList//newUserList;//JSON.parse(JSON.stringify(newUserList));   
    }
    catch (err) {
      
    }
  }






  async setProjectDetailFlag(){
    try {
      this.ProjectDetailOpen =! this.ProjectDetailOpen
      let obj:any={};
      obj = {
        'id': sessionStorage.getItem('userId'),
        'menuStatus':this.viewFunctionList,
        'projectDetailStatus':this.ProjectDetailOpen,
        'ProjectTableStatus':this.tableDate,
      }
      const res: any = await this.commonUtilService.setBookingMenu(obj);
      this.sharedservice.setProjectDetailOpen(this.ProjectDetailOpen);

    } catch (error) {
      
    }
  }

  async setGoogleColor(){
    try {
      if(this.loggedInUserDetails.userSettingDTO.googleCalAccess == false){
        return this.toastr.warning('This feature requires calendar permisson. Please, contact your administrator if you are interested.')
      }
      let obj:any={};
      obj = {
        'id': sessionStorage.getItem('userId'),
        'menuStatus':this.viewFunctionList,
        'projectDetailStatus':this.ProjectDetailOpen,
        'googleSync':this.googleSync,
        'googleTheme':this.selectedColor
      }
      const res: any = await this.commonUtilService.setBookingMenu(obj);
      this.colorbox = false

    } catch (error) {
      
    }
  }

  async setGooglePref(){
    try {
      if(this.loggedInUserDetails.userSettingDTO.googleCalAccess == false){
        this.googleSync =  false;
        return this.toastr.warning('This feature requires calendar permisson. Please, contact your administrator if you are interested.')
      }
      let obj:any={};
      this.googleSync =! this.googleSync
      obj = {
        'id': sessionStorage.getItem('userId'),
        'menuStatus':this.viewFunctionList,
        'projectDetailStatus':this.ProjectDetailOpen,
        'googleSync':this.googleSync,
        'googleTheme':this.selectedColor
      }
      const res: any = await this.commonUtilService.setBookingMenu(obj);
      if(this.googleSync){
        console.log(res)
        this.sharedservice.signIn()
      } else {
        this.sharedservice.logout()

      }
      this.colorbox = false

    } catch (error) {
      
    }
  }





  getAllMessage = () => {
    let to = this.toUserObject['id'] || '';
    let from = this.loggedInUserDetails.id || '';
    let data = {
      from: from,
      to: to,
      msg_type: this.selectedTab
    };
    // this.chatService.getAllMessage(data);
  }

  onKeyUp = (event) => {
    if (event.keyCode == 13) {
      this.sendMessage();
    }
    this.onKeyDownNotEnter();
  }

  getAllMessageNotifications = () => {
    let from = this.loggedInUserDetails.id || '';
    let data = {
      to: from
    }
    // this.chatService.getAllMessageNotifications(data);
  }

  createNotificationObject = (list) => {
    
    list.forEach(row => {
      
      const notifyIndex = this.getNotifyIndexForMsg(row);
      if (this.notificationsObject[notifyIndex]) {
        this.notificationsObject[notifyIndex] = {
          count: this.notificationsObject[notifyIndex]['count'] + 1,
          data: row,
          lastUpdate: row.message_send_time//new Date()
        }
      }
      else {
        this.notificationsObject[notifyIndex] = {
          count: 1,
          data: row,
          lastUpdate: row.message_send_time//new Date()
        }
      }
    });
    this.updateMainNotificationCount();
  }

  sendMessage = () => {
    try {
      this.msg = this.msg.trim(); 
    }
    catch(ex) {

    }
    if (this.msg == '' || this.msg == undefined) {
      return;
    }

    let msgType = this.toUserObject['user_type'];
    let to = this.toUserObject['id'] || '';
    let from = this.loggedInUserDetails.id || '';
    let senderId = from;
    let senderName = this.loggedInUserDetails.firstName + ' ' + this.loggedInUserDetails.lastName;
    

    if (to == '' || from == '') {
      return;
    }

    // check is to user is online or not
    let seen = false;
    // if(this.allOnlineUsersList.hasOwnProperty(to)){
    //   seen=false;
    // }
    // else{
    //   seen=false;
    // }
    let data = {
      from: from,
      to: to,
      sender_id: senderId,
      sender_name: senderName,
      msg_type: msgType,
      msg: this.msg,
      seen: seen
    };

    // this.chatService.sendMessage(data);
    this.msg = "";
    // this.messageSendUserObject(to);
  }

  messageSendUserObject = (to) => {
    // this.userList=this.userList.map((user)=>{
    //   if(user.id==to){
    //     user.lastUpdate=new Date();
    //   }
    //   return user;
    // })

    this.userList.forEach((user) => {
      if (user.id == to && user.user_type === 1) {
        user.lastUpdate = this.lastMessageSentTime;//new Date();
      }
    })

    this.sortUserList();
  }

  getTimeFormat = (date) => {
    try {
      return moment(date).format("hh:mm A");
    }
    catch (err) {
      
      return "";
    }
  }

  getDateFormat = (date) => {
    try {
      return moment(date).format("ddd,D MMM")
    }
    catch (err) {
      
      return "";
    }
  }

  // typing functionality
  typing = false;
  timeout = undefined;
  timeoutFunction = () => {
    try {
      let to = this.toUserObject['id'] || '';
      let from = this.loggedInUserDetails.id || '';
      this.typing = false;
      // this.chatService.setUserIsTyping({ typing: false, from: from, to: to });
    }
    catch (err) {
      
    }
  }

  onKeyDownNotEnter = () => {
    try {
      let to = this.toUserObject['id'] || '';
      let from = this.loggedInUserDetails.id || '';
      if (this.typing == false) {
        this.typing = true
        // this.chatService.setUserIsTyping({ typing: true, from: from, to: to });
        this.timeout = setTimeout(() => this.timeoutFunction(), 500);
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.timeoutFunction(), 500);
      }
    }
    catch (err) {
      
    }
  }

  returnTypingName = (toUserObject) => {
    let name = toUserObject.firstName || "";
    if (name == "") {
      return toUserObject.lastName;
    }
    else {
      return toUserObject.firstName;
    }
  }

  scrollBottom = () => {
    try {
      var element = document.getElementById('messages');
      element.scrollTop = 1000000//element.clientHeight;
    }
    catch (err) {
      
    }

  }


  hideDigitalSignatureModal(event) {
    this.showDigitalSignatureModal = false;
  }

  showLogOutModal() {
    this.showLogot = !this.showLogot;
   
  }

  showDefaultLanguage = (languages) => {
    if (languages.length == 0) {
      return true
    }
    let val = this.languages.filter(l => l === 'English').length;
    if (val == 0) {
      return true;
    }
    else {
      return false;
    }
  }



  getOtherMaxTwoFunction() {
    let list = new Array<string>();
    this.loggedInUserDetails.resource.functions.filter(f => f.primaryFunction === false).slice(0, 2).forEach(element => {
      list.push(element.function.qualifiedName ? element.function.qualifiedName : '');
    });
    return list;
  }

  // saveMyAccount() {
  //   if (!this.validateAccount()) {
  //     return;
  //   }

  //   this.setPrimaryData();
  //   let body = {
  //     "version": this.loggedInUserDetails.version,
  //     "firstName": this.userForm.get('firstName').value,
  //     "lastName": this.userForm.get('lastName').value,
  //     "address": {
  //       "id": this.loggedInUserDetails.address.id,
  //       "city": this.userForm.get('city').value,
  //       "country": this.userForm.get('country').value,
  //     },
  //     "userSettingDTO": {
  //       "timezone": this.userForm.get('timeZone').value,
  //       "emails": this.loggedInUserDetails.userSettingDTO.emails,
  //       "telephones": this.loggedInUserDetails.userSettingDTO.telephones,
  //       "preferredClient" : this.userForm.get('preferredClient').value,
  //       "landingPage": this.userForm.get('preferredLandingPage').value,
  //       "timelineScale" : this.userForm.get('timelineScale').value,
  //     },
  //     "uiLanguage": this.userForm.get('language').value,
  //     "removePhoto": this.pictureUrl === '' ? true : false,
  //   };
  //   this.blockedPanel = true;
  //   this.httpService.callApi('editUserProfile', { pathVariable: this.loggedInUserDetails.id, body: body }).subscribe((response) => {
  //     this.loggedInUserDetails = response;
  //     this.uploadPicture(this.loggedInUserDetails.id);
  //     this.toastr.success('Successfully Saved', 'My Account');
  //     this.blockedPanel = false;
  //     window.location.reload();
  //   }, error => {
  //     this.blockedPanel = false;
  //     this.toastr.warning(error.error.message, 'My Account');
  //   });
  //   // this.useLanguage(this.userForm.get('language').value);
  //   this.editable = false;
  //   this.disableFields();
   
  // }

  // setPrimaryData() {
  //   let email = this.userForm.get('primaryEmail').value;
  //   let telephone = this.userForm.get('primaryPhone').value;
  //   if (email) {
  //     this.loggedInUserDetails.userSettingDTO.emails.forEach(e => {
  //       if (e.email === email) {
  //         e.primaryEmail = true;
  //       } else {
  //         e.primaryEmail = false;
  //       }
  //     });
  //   }

  //   if (telephone) {
  //     this.loggedInUserDetails.userSettingDTO.telephones.forEach(t => {
  //       if (t.telephone === telephone) {
  //         t.primaryTelephone = true;
  //       } else {
  //         t.primaryTelephone = false;
  //       }
  //     });
  //   }
  // }

  // disableFields() {
  //   if (this.editable) {
  //     this.userForm.get('firstName').enable();
  //     this.userForm.get('lastName').enable();
  //     this.userForm.get('country').enable();
  //     this.userForm.get('city').enable();
  //     this.userForm.get('primaryEmail').enable();
  //     this.userForm.get('primaryPhone').enable();
  //     this.userForm.get('language').enable();
  //     this.userForm.get('timeZone').enable();
  //     this.userForm.get('avatar').enable();
  //     this.inputImage.nativeElement.disabled = false;
  //   } else {
  //     this.userForm.get('firstName').disable();
  //     this.userForm.get('lastName').disable();
  //     this.userForm.get('country').disable();
  //     this.userForm.get('city').disable();
  //     this.userForm.get('primaryEmail').disable();
  //     this.userForm.get('primaryPhone').disable();
  //     this.userForm.get('language').disable();
  //     this.userForm.get('timeZone').disable();
  //     this.userForm.get('avatar').disable();
  //     this.inputImage.nativeElement.disabled = true;
  //   }
  // }

  // validateAccount() {
  //   if (this.userForm.invalid) {
  //     if (this.userForm.get('firstName').invalid) {
  //       this.toastr.warning(this.userForm.get('firstName').errors.required ? 'First name is required' : 'First name is not valid', 'My Account');
  //     }
  //     if (this.userForm.get('lastName').invalid) {
  //       this.toastr.warning(this.userForm.get('lastName').errors.required ? 'Last name is required' : 'Last name is not valid', 'My Account');
  //     }
  //     return false;
  //   }
  //   return true;
  // }

  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  onProfilePictureChange(event: any) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileExtension = file.name.split('.').pop().toLowerCase();
      if (this.isInArray(this.allowedExtensionsForPhoto, fileExtension)) {
        if (file.size > 2097152) {
          this.inputImage.nativeElement.value = '';
          this.toastr.warning('File size should not be greater than 2MB.', 'My Account');
          return;
        }
        // this.userForm.get('avatar').setValue(file);
        this.readImage(file);
      } else {
        this.inputImage.nativeElement.value = '';
        this.toastr.warning('Only jpeg, jpg or png format allowed!!', 'My Account');
        return;
      }
    }
  }
  readImage(file: any): any {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      let target: any = event.target;
      this.pictureUrl = target.result;
    }
  }

  removeImage() {
    this.pictureUrl = '';
    // this.userForm.get('avatar').setValue(null);
    this.inputImage.nativeElement.value = '';
  }

  /*-  To upload picture*/
  // uploadPicture(personnelId: number) {

  //   if (this.userForm.get('avatar').value) {
  //     let body = new FormData();
  //     body.append('discriminator', 'person');
  //     body.append('fileContent', this.userForm.get('avatar').value);
  //     this.httpService.callApi("uploadPicture", { body: body, pathVariable: personnelId }).subscribe((respone) => {
  //     }, (error) => {
  //       this.toastr.warning('Unable to upload the profile picture', 'My Account');
  //     });
  //   }
  // }



  getRecentNotifications(lowestNotificationId:number) {
    if (this.userNotificationList.length === 0) {
      this.getLoggedInUserNotification(0);
      return;
    } 
    let highestNotificationId = this.userNotificationList[0].id;
    this.httpService.callApi('getRecentNotifications', {pathVariable: String(highestNotificationId)}).subscribe((response) => {
      response.forEach(x => {
        if(x.requesterID != undefined && x.requesterID != null ){
          let personalData:any = this.getPersonals(x.requesterID)
          if(personalData != undefined && personalData != null){
            x.requesterName =  personalData.fullName
            console.log(personalData)
          } else {
            x.requesterName =  'TM User'
            console.log(x.requesterName)

          }
        } else {
          x.requesterName =  'TM User'
          console.log(x.requesterName)

        }
        x.displayTime = moment(x.notificationTime).format('YYYY-MM-DD HH:mm:ss')
      });
      this.userNotificationList = this.userNotificationList || [];
      if (response.length > 0) {
        this.toastr.success("Notifications","New Notification");
        this.lowestNotificationId = response[response.length-1].id;
        this.countNotification+=response.filter(x => !x.readStatus).length;
        this.noMoreNotifications = false;
        if (this.userNotificationList) {
          this.userNotificationList.push(...response);
        } else {
          this.userNotificationList = response;
        }
      } else {
        this.noMoreNotifications = true;        
      }
    }, error => {

    });
  }


  getMarketPlaceByUserId() {
    this.httpService.callApi('getMarketPlace', {}).subscribe((response) => {
      if (response !=null) {
        this.marketPlaceId = response.id;
        this.marketPlaceForm.get('globalPersonnel').setValue(response.globalPersonnel);
        this.marketPlaceForm.get('globalResource').setValue(response.globalResource);
        sessionStorage.setItem("globalPersonnel", response.globalPersonnel);
        sessionStorage.setItem("globalResource", response.globalResource);
      }
    });
  }

  async getAllKeywordstags(){

    if (this.sharedservice.getglobalKeywords().length != 0) {
      this.allListTags = this.sharedservice.getglobalKeywords();
      this.filteredOptions = this.tagsInput.valueChanges.pipe(
        startWith(""),
        map((name) => (name ? this._filter(name) : this.allListTags.filter(el => el.visible ==  true).slice()))
      );
    } else {
      try {
        const res:any = await this.commonUtilService.getAllKeywordsTags();
        if(res.length > 0){
          this.sharedservice.setglobalKeywords(res)
        }
      } catch (error) {
        console.log(error)
      }
    }

   
  }


saveMarketPlace() {
  let body = {
    "id" : this.marketPlaceId!=null?this.marketPlaceId:null,
    "globalPersonnel" : this.marketPlaceForm.get('globalPersonnel').value,
    "globalResource" : this.marketPlaceForm.get('globalResource').value
  }
  this.httpService.callApi('saveMarketPlace', {body:body}).subscribe((response) => {
    document.location.reload();
  });
}

  openHelpCenter() {
    window.open('https://teamium.zohodesk.com/', '_blank');
  }

  openCreateProject() {
    window.open('https://youtu.be/Qo0NzgV1-ck', '_blank');
  }

  openSupport() {
    zE('webWidget', 'open');
    this.openHelp = false;
  }

  
  generateSupportPassword(){
    this.httpService.callApi('setSupportPassword', {}).subscribe((response) => {
      if (response !=null) {
       alert("Provide this password to Teamium Support team : "+ response.supportPassword);
      }
    });
  }

  revokeSupportAccess(){
    this.httpService.callApi('revokeSupportAccess', {}).subscribe((response) => {
      this.toastr.success("Access revoked")
    }, error => {
      this.toastr.warning("Please contact Teamium Support Team.")
    });
  }

  async saveTask(obj){
    try {
      let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'UTC';
      let body:any= null;

      let personId =  null;
      if(this.taskAssignee != undefined && this.taskAssignee != null){
        let indexAsignee =  this.personnelList.findIndex(el => el.fullName == this.taskAssignee);
        if(indexAsignee != -1){
          personId = this.personnelList[indexAsignee].id
        } 
      }

      if(this.recordId == null || this.recordId == ""){
        return this.toastr.warning("Please select Project");
      }

      let recordId =  null;
      if(this.recordId != undefined && this.recordId != null){
        let projectId =  this.projectArr.findIndex(el => el.projectname.trim() == this.recordId.trim());
        if(projectId != -1){
          recordId = this.projectArr[projectId].id
        } 
      }
    
      if(obj == null){
        if(this.taskStatus == null || this.taskStatus == ""){
          return this.toastr.warning("Please select Task Status");
        }
  
     
       
        if(this.taskName == null || this.taskName == ""){
          return this.toastr.warning("Please Enter Name of Task");
        }
        body = {
          "meetingLineId": null ,
          "subject": this.taskDesc,
          "action": "Task",
          "personId": personId,
          "status": this.taskStatus,   
          "photo":  null,
          "date":(this.taskDate == null || this.taskDate == "") ? null : moment(this.taskDate).tz(timezone).format(),
          "isPublish": false,
          "recordId":recordId,
          "bookingId": null,
          "taskName":this.taskName
        }
      } else {
        let status = 'To Do';
        if(obj.status == 'Done'){
          status = 'To Do'
        } else {
          status = 'Done'
        }
        body = {
          "meetingLineId": obj.meetingLineId ,
          "subject":  obj.subject,
          "action": "Task",
          "personId":  personId,
          "status": status,   
          "photo":  null,
          "date":(obj.date == null || obj.date == "") ? null : moment(obj.date).tz(timezone).format(),
          "isPublish": false,
          "recordId":recordId,
          "bookingId": obj.bookingId,
          "taskName":obj.taskName
        }
      }
    
      const res:any = await this.projectSerice.createTaskProject(body);
      this.saveAttachment(res.meetingLineId);
      this.sharedservice.settaskValue('Changed');
      
      let index = this.projectNoteList.findIndex(el => el.meetingLineId == res.meetingLineId)
      if(index != -1){
        this.projectNoteList[index].status =  res.status
      }

      this.projectNoteList = [...this.projectNoteList];
      // this.getAllUserTask()
      this.recordId = null;
      this.taskName = null;
      this.taskDesc = null;
      this.taskName = null;
      this.taskDate = new Date();
      this.taskStatus = 'To Do';
      this.taskAssignee = null;
      this.taskModalFlag = false;
      if(obj == null){
        this.toastr.success("New Task created");
      } else {
        this.toastr.success("Task Updated Successfully");
      }
      this.blockedPanel = false;


  
    } catch (error) {
      console.log(error)
      this.toastr.warning(error.error.message)
  
    }
  }

  async saveIncident(obj){
    try {
      let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'UTC';
      let body:any= null;

      let personId =  null;
      if(this.taskAssignee != undefined && this.taskAssignee != null){
        let indexAsignee =  this.personnelList.findIndex(el => el.fullName == this.taskAssignee);
        if(indexAsignee != -1){
          personId = this.personnelList[indexAsignee].id
        } 
      }

      if(this.recordId == null || this.recordId == ""){
        return this.toastr.warning("Please select Project");
      }

      let recordId =  null;
      if(this.recordId != undefined && this.recordId != null){
        let projectId =  this.projectArr.findIndex(el => el.projectname.trim() == this.recordId.trim());
        if(projectId != -1){
          recordId = this.projectArr[projectId].id
        } 
      }
    
      if(obj == null){
        if(this.taskStatus == null || this.taskStatus == ""){
          return this.toastr.warning("Please select Incident Status");
        }
  
     
       
        if(this.taskName == null || this.taskName == ""){
          return this.toastr.warning("Please Enter Name of Incident");
        }
        body = {
          "meetingLineId": null ,
          "subject": this.taskDesc,
          "action": "Incident",
          "personId": personId,
          "status": this.taskStatus,   
          "severity": this.taskSeverity,   
          "photo":  null,
          "date":(this.taskDate == null || this.taskDate == "") ? null : moment(this.taskDate).tz(timezone).format(),
          "isPublish": false,
          "recordId":recordId,
          "bookingId": null,
          "taskName":this.taskName
        }
      } else {
        let status = 'To Do';
        if(obj.status == 'Done'){
          status = 'To Do'
        } else {
          status = 'Done'
        }
        body = {
          "meetingLineId": obj.meetingLineId ,
          "subject":  obj.subject,
          "action": "Incident",
          "personId":  personId,
          "status": status,   
          "photo":  null,
          "date":(obj.date == null || obj.date == "") ? null : moment(obj.date).tz(timezone).format(),
          "isPublish": false,
          "recordId":recordId,
          "bookingId": obj.bookingId,
          "taskName":obj.taskName
        }
      }
    
      const res:any = await this.projectSerice.saveIncidentReport(body);
      this.saveAttachment(res.meetingLineId);
      this.sharedservice.settaskValue('Changed');
      
      let index = this.projectNoteList.findIndex(el => el.meetingLineId == res.meetingLineId)
      if(index != -1){
        this.projectNoteList[index].status =  res.status
      }

      this.projectNoteList = [...this.projectNoteList];
      // this.getAllUserTask()
      this.recordId = null;
      this.taskName = null;
      this.taskDesc = null;
      this.taskName = null;
      this.taskDate = new Date();
      this.taskStatus = 'To Do'; 
      this.taskSeverity = '1'; 
      this.taskAssignee = null;
      this.incidentModalFlag = false;
      if(obj == null){
        this.toastr.success("New Incident created");
      } else {
        this.toastr.success("Incident Updated Successfully");
      }
      this.blockedPanel = false;


  
    } catch (error) {
      console.log(error)
      this.toastr.warning(error.error.message)
  
    }
  }

  async selectaTeamFrameIO(){
    if(this.frameioService.framioTeams.length > 0){
      let teamsArr =  this.frameioService.framioTeams;
      this.frameioService.getAllProjectsFromFrameio(teamsArr[0].id);
    } else {
     let teams =  await this.frameioService.getFrameIoTeams();
     if(teams){
      let teamsArr =  this.frameioService.framioTeams
      this.frameioService.getAllProjectsFromFrameio(teamsArr[0].id);
     }
    }
  }

  getNotes(){

 
    this.projectNoteList = [];
    this.httpService.callApi('getMeetingDetailsByUserId',{}).subscribe((response) => {
      this.projectNoteList = response;
      let today = moment(new Date(), "DD/MM/YYYY")
      let past7Day:any =  new Date()
      past7Day.setDate(past7Day.getDate() - 7)
      past7Day = moment(past7Day, "DD/MM/YYYY")
      for (var i = this.projectNoteList.length - 1; i >= 0; i--) {
        this.projectNoteList[i].note = true;
          if(this.projectNoteList[i].notificationChecked){
            let isBetween = moment(this.projectNoteList[i].date).isBetween(past7Day,today)
            if(!isBetween){
              this.projectNoteList.splice(i,1)
            }
          }
      }
      
       }, error => {
           this.blockedPanel=false;
           this.toastr.warning(error.error.message);
         });
  }  

  async getAllUserTask(){
    try {
      let userid = sessionStorage.getItem("userId");
      this.projectNoteList = this.projectNoteList.filter(item => item.note == true)
      let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'UTC';

      const res:any = await this.projectSerice.getAllTaskDashBoardForUser(userid);

      let past7Day:any =  new Date()
      let next7Day:any =  new Date()
      past7Day.setDate(past7Day.getDate() - 7)
      next7Day.setDate(next7Day.getDate() + 7)
      past7Day = moment(past7Day, "DD/MM/YYYY")
      next7Day = moment(next7Day, "DD/MM/YYYY")
      for (var i = res.length - 1; i >= 0; i--) {
        res[i].note = false;

          if(res[i].date){
            res[i].date =  new Date(moment(res[i].date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"))
          }
          let isBetween = moment(res[i].date).isBetween(past7Day,next7Day)
          if(!isBetween){
            res.splice(i,1)
          } else {
            this.projectNoteList.push(res[i])
          }
          
      }

      this.projectNoteList = this.projectNoteList.filter((value, index, self) => index === self.findIndex((t) => (
        t.id === value.id && t.subject === value.subject
      ))
    )
    } catch (error) {
      console.log(error)
    }
  }

  async getAllUserIncident(){
    try {
      let userid = sessionStorage.getItem("userId");
      this.projectNoteList = this.projectNoteList.filter(item => item.note == true)
      let  timezone = this.loggedInUserDetails.userSettingDTO.timezone ? this.loggedInUserDetails.userSettingDTO.timezone : 'UTC';

      const res:any = await this.projectSerice.getAllIncidentDashBoardForUser(userid);

      let past7Day:any =  new Date()
      let next7Day:any =  new Date()
      past7Day.setDate(past7Day.getDate() - 7)
      next7Day.setDate(next7Day.getDate() + 7)
      past7Day = moment(past7Day, "DD/MM/YYYY")
      next7Day = moment(next7Day, "DD/MM/YYYY")
      for (var i = res.length - 1; i >= 0; i--) {
        res[i].note = false;

          if(res[i].date){
            res[i].date =  new Date(moment(res[i].date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"))
          }
          let isBetween = moment(res[i].date).isBetween(past7Day,next7Day)
          if(!isBetween){
            res.splice(i,1)
          } else {
            this.projectNoteList.push(res[i])
          }
          
      }

      this.projectNoteList = this.projectNoteList.filter((value, index, self) => index === self.findIndex((t) => (
        t.id === value.id && t.subject === value.subject
      ))
    )
    } catch (error) {
      console.log(error)
    }
  }

  filterDataUserTask(status) {
    if (status == "ALL") {
      this.completedFilterUser =  null;
      // this.statusheadingName = "ALL";
      this.projectNoteList.sort((a, b) => (a.date > b.date ? 1 : -1));
    } else if(status == 'NEWEST') {
      this.completedFilterUser =  null;
      this.projectNoteList.sort((a, b) => (a.date < b.date ? 1 : -1));
    } else if(status == 'OLDEST') {
      this.completedFilterUser =  null;
      this.projectNoteList.sort((a, b) => (a.date > b.date ? 1 : -1));
    } else if(status == 'PROJECT') {
      this.completedFilterUser =  null;
      this.projectNoteList.sort((a, b) => (a.name < b.name ? 1 : -1));
    } else if(status == 'COMPLETED') {
      this.completedFilterUser =  "Done";
    } 
  }

  changeToggle(obj){
    if(obj.note){
        this.changeNoteNotificationToggle(obj.meetingLineId)
    } else {
      this.saveTask(obj)
    }
  }

  changeNoteNotificationToggle(meetingLineId: number){
  
    this.projectNoteList = [];
    let body = {
      "meetingLineId": meetingLineId,
      "notificationChecked": true
    }
    this.httpService.callApi('saveNoteNotificationToggle', { body: body }).subscribe((response) => {
      this.getNotes()
  }, error => {
    this.blockedPanel = false;
    this.toastr.warning(error.error.message);
  });
  }

  twoFaModal : boolean = false;
  totpValue : string;
  qrPath : string;
  tokenValue : string;

  toggle2FA(){
    this.httpService.callApi('toggle2FA', {}).subscribe((response) => {
      if (response != null) {
        let tv:string = response.token;  
        if(tv.toString() != "false"){
          this.twoFaModal = true;
          this.qrPath = "https://zxing.org/w/chart?cht=qr&chs=250x250&chld=M&choe=UTF-8&chl=otpauth://totp/teamiumapp.com?secret=" + response.token + "&issuer=teamiumapp";
          this.tokenValue = response.token;
        }else{
          this.qrPath = "";
        }
      }
    });
  }

  verifyTOTPNew(){
    this.httpService.callApi('verify_2fa_new', {pathVariable : this.totpValue}).subscribe((response) => {
      if (response !=null) {
        if (!response)
       alert("Invalid code or please scan again to setup.");
      else 
       this.twoFaModal = false;
       this.totpValue = null;
      }
    });
  }

  close2FAModal() {
    if (!this.loggedInUserDetails.user2fa)
     this.loggedInUserDetails.user2fa == false;
     this.twoFaModal = false;
  
}

 loadScript() {
  let chatScript = document.createElement("script");
  chatScript.type = "text/javascript";
  chatScript.async = true;
  chatScript.src = "https://desk.zoho.com/portal/api/web/inapp/763373000000450001?orgId=785471897";
  document.body.appendChild(chatScript);

  // let WidgetScript = document.createElement("script");
  // WidgetScript.type = "text/javascript";
  // WidgetScript.async = true;
  // WidgetScript.src = "https://apis.google.com/js/api.js";
  // document.body.appendChild(WidgetScript);
  
  }



}

